import { useFormikContext } from "formik";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { down } from "styled-breakpoints";
import { useEffect } from "react";

import { LyteButton } from "components/common/buttons";
import { usePostDraftInvoice, usePostTestSendInvoice } from "hooks/invoices";
import useApi from "hooks/useApi";

import { INVOICE_TYPES } from "./constants";

const InvoiceWizardControl = ({ publicInvoice, canNext, user }) => {
  const {
    params: { step, wizardMode, invoiceRef },
  } = useRouteMatch();
  const history = useHistory();

  const downMd = useBreakpoint(down("md"));

  const {
    values,
    handleSubmit,
    validateForm,
    isSubmitting,
    errors,
    setTouched,
    dirty,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext();

  const page1Errors =
    "clientName" in errors ||
    "businessName" in errors ||
    "addressLine1" in errors ||
    "addressLine2" in errors ||
    "email" in errors ||
    "mobileCountryCode" in errors ||
    "mobileNumber" in errors ||
    "country" in errors ||
    "postalCode" in errors;

  const page2Errors =
    "invoiceNumber" in errors ||
    "projectTitle" in errors ||
    "issuedDateTime" in errors ||
    "paymentDueDays" in errors ||
    "currency" in errors ||
    "lateFeeRate" in errors ||
    "invoiceType" in errors ||
    "invoiceItems" in errors;

  const page3Errors = "agreeAuthClause" in errors;

  const page4Errors = "agreeProhibitClause" in errors || "agreeCollectionClause" in errors;

  const isDisabled =
    page1Errors ||
    page2Errors ||
    page3Errors ||
    page4Errors ||
    (values.invoiceType === INVOICE_TYPES.LYTE && !user?.hasApprovedBankAccount);

  const { mutate: saveDraftInvoice, isLoading: saveDraftIsLoading } = usePostDraftInvoice({
    onSuccess: (data) => {
      history.replace(`/invoice/listing`);
    },
  });

  const saveDraftHandler = async (values) => {
    const updatedValues = {
      ...values,
      invoiceId: publicInvoice?.id,
      invoiceFiles: values?.invoiceFiles?.map((file) => file.id) || [],
      files: values?.invoiceFiles?.map((file) => file.id) || [],
    };
    await saveDraftInvoice(updatedValues);
  };

  const { mutate: testSendHandler, isLoading: testSendIsLoading } = usePostTestSendInvoice();

  const sendTestHandler = async (values) => {
    const updatedValues = {
      ...values,
      invoiceFiles: values?.invoiceFiles?.map((file) => file.id) || [],
      files: values?.invoiceFiles?.map((file) => file.id) || [],
    };
    await testSendHandler(updatedValues);
  };

  const handleScrollTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 0);
  };

  return (
    <div className="mt-3 gap-2 d-flex flex-column-reverse flex-lg-row justify-content-between">
      <div className="gap-2 d-flex flex-column-reverse flex-lg-row align-items-center">
        <LyteButton
          className={`${downMd && "w-100"}`}
          variant="secondary"
          disabled={!dirty || isSubmitting || saveDraftIsLoading || testSendIsLoading}
          onClick={() => saveDraftHandler(values)}
        >
          Save Draft
        </LyteButton>
        {+step === 4 && (
          <LyteButton
            className={`${downMd && "w-100"}`}
            variant="secondary"
            disabled={isSubmitting || saveDraftIsLoading || testSendIsLoading}
            onClick={() => sendTestHandler(values)}
          >
            Send Test Email to Yourself
          </LyteButton>
        )}
      </div>
      <div className="gap-2 d-flex flex-column-reverse flex-lg-row align-items-center">
        {/* Back button - only appears after page 1 */}
        {+step > 1 && (
          <LyteButton
            className={`${downMd && "w-100"}`}
            disabled={isSubmitting || saveDraftIsLoading || testSendIsLoading}
            variant="secondary"
            onClick={() => {
              handleScrollTop();
              history.push(
                `/invoice/${wizardMode}/${+step - 1}${invoiceRef ? `/${invoiceRef}` : ""}`
              );
            }}
          >
            Back
          </LyteButton>
        )}
        {/* Next button - do not appear on page 4 */}
        {+step < 4 && (
          <LyteButton
            className={`${downMd && "w-100"}`}
            type="button"
            disabled={+step === 2 && !canNext}
            onClick={() => {
              handleScrollTop();
              validateForm();
              document.forms[0].reportValidity();

              // Touch the forms first to trigger validation
              if (+step === 1) {
                if (page1Errors) {
                  return setTouched({
                    clientName: true,
                    businessName: true,
                    addressLine1: true,
                    addressLine2: true,
                    email: true,
                    mobileCountryCode: true,
                    mobileNumber: true,
                    country: true,
                    postalCode: true,
                  });
                }

                if (!page1Errors) {
                  return history.push(
                    `/invoice/${wizardMode}/2${invoiceRef ? `/${invoiceRef}` : ""}`
                  );
                }
              }

              if (+step === 2) {
                if (page2Errors) {
                  return errors?.invoiceItems?.forEach((item, index) => {
                    Object.keys(item).forEach((key) => {
                      setFieldTouched(`invoiceItems[${index}].${key}`);
                    });
                  });
                }

                if (!page2Errors && canNext) {
                  return history.push(
                    `/invoice/${wizardMode}/3${invoiceRef ? `/${invoiceRef}` : ""}`
                  );
                }
              }

              if (+step === 3) {
                if (page3Errors) {
                  return setTouched({
                    agreeAuthClause: true,
                  });
                }

                if (!user?.isKycApproved) {
                  return history.push(`/invoice/create/4`);
                }

                if (!page3Errors) {
                  return history.push(
                    `/invoice/${wizardMode}/4${invoiceRef ? `/${invoiceRef}` : ""}`
                  );
                }
              }
              return history.push(`/invoice/create/${+step}`);
            }}
          >
            Continue
          </LyteButton>
        )}
        {/* Submit button - appears only on page 4 */}
        {+step === 4 && (
          <LyteButton
            className={`${downMd && "w-100"}`}
            type="button"
            disabled={
              isSubmitting ||
              isDisabled ||
              saveDraftIsLoading ||
              testSendIsLoading ||
              !user?.isKycApproved
            }
            onClick={handleSubmit}
          >
            Send
          </LyteButton>
        )}
      </div>
    </div>
  );
};

export default InvoiceWizardControl;
