import { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, useField } from "formik";
import PropTypes from "prop-types";

import { StyledField, StyledLabel, StyledPasswordIcon } from "./styles";

const FormikInput = ({
  label,
  type,
  name,
  placeholder,
  className,
  disabled,
  isRequired,
  description,
  ...restProps
}) => {
  const [field, meta, helpers] = useField(name);
  const [hide, setHide] = useState(true);

  const inputType = type === "password" ? (hide ? "password" : "input") : type;

  const handleChange = (event) => {
    const input = event.target.value;
    if (type === "number") {
      const sanitizedInput = input
        .replace(/[^\d.]/g, "")
        .replace(/(?:^|(?<=[,.]))0+(?=\d)/g, "")
        .replace(/(\..*)\./g, "$1")
        .replace(/(\.\d{2}).*/g, "$1");
      return helpers.setValue(sanitizedInput);
    }
    if (type === "tel") {
      const sanitizedInput = input.replace(/[^\d]/g, "").replace(/^0+(?=\d)/, "");
      return helpers.setValue(sanitizedInput);
    }
    return helpers.setValue(input);
  };

  return (
    <StyledField
      className={`field-container ${className} ${
        meta?.touched && meta?.error ? "form-invalid" : ""
      } ${meta?.value && meta?.touched && !meta?.error ? "form-success" : ""}`}
    >
      {label && <StyledLabel htmlFor={name}>{`${label}${isRequired ? "*" : ""}`}</StyledLabel>}
      <div className="position-relative">
        <Field
          type={inputType}
          id={name}
          name={name}
          className="form-control"
          disabled={disabled}
          placeholder={placeholder}
          data-cypress-id={`cypress-input-${inputType}-${name}`}
          onChange={(event) => handleChange(event)}
          {...restProps}
        />
        {type === "password" && (
          <StyledPasswordIcon onClick={() => setHide(!hide)}>
            {hide ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
          </StyledPasswordIcon>
        )}
      </div>
      {description && <p className="description-text">{description}</p>}
      {meta?.touched && (
        <ErrorMessage
          data-cypress-id={`cypress-input-${inputType}-${name}-error-message`}
          name={name}
          component="div"
          className="error-text"
        />
      )}
    </StyledField>
  );
};

FormikInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.node,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
};

FormikInput.defaultProps = {
  label: "",
  className: "",
  type: "input",
  placeholder: "",
  name: "",
  disabled: false,
  description: "",
  isRequired: false,
};

export default FormikInput;
