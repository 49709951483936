import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { useUserDataSingleUse } from "hooks/useUserData";
import KycSubmitted from "components/kyc/onfido-kyc-success";
import { PENDING_SUBMISSION, PENDING_VERIFICATION } from "components/kyc/constants";

import { useInitiateOnfidoVerification, useSubmittedOnfidoVerification } from "./service";

const OnfidoKyc = () => {
  const { replace } = useHistory();
  const iframeRef = useRef(null);
  const targetURL = process.env.REACT_APP_ORIGIN_URI; // Replace with the URL you want to trigger the reload for
  const [kycIframe, setKycIframe] = useState("");
  const [isPending, setIsPending] = useState(false);

  const { mutate: mutateOnfidoVerificationData } = useInitiateOnfidoVerification();
  const { mutate: mutateSubmittedOnfidoVerificationData } = useSubmittedOnfidoVerification();

  const { data: userData, refetch: refetchUserData } = useUserDataSingleUse({
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    onSettle: () => false,
    onSuccess: (response) => {
      setIsPending(response?.kycStatus === PENDING_VERIFICATION && true);
      if (response?.isKycApproved) {
        replace("/");
      } else if (!response?.kycStatus || response?.kycStatus === PENDING_SUBMISSION) {
        mutateOnfidoVerificationData(
          {},
          {
            onSuccess: (responseMutate) => {
              if (!isPending && responseMutate) setKycIframe(responseMutate);
            },
            enabled: !response?.kycStatus || response?.kycStatus === PENDING_SUBMISSION,
            refetchOnWindowFocus: false,
          }
        );
      }
    },
  });

  useEffect(() => {
    const iframe = iframeRef.current;
    const checkIframeURL = () => {
      try {
        if (iframe?.contentWindow?.location?.origin.includes(targetURL)) {
          mutateSubmittedOnfidoVerificationData(
            {},
            {
              onSuccess: () => {
                refetchUserData();
              },
            }
          );
          setIsPending(true);
        }
      } catch {
        /* Empty catch */
      }
    };

    const interval = setInterval(checkIframeURL, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetURL, userData]);

  return isPending ? (
    <KycSubmitted />
  ) : (
    <iframe
      ref={iframeRef}
      title="kyc"
      style={{
        width: "100%",
        height: "calc(100vh - 70px)",
      }}
      src={kycIframe}
      allow="autoplay; camera; microphone"
    />
  );
};

export default OnfidoKyc;
