import { useEffect } from "react";

import KycSubmitted from "components/kyc/onfido-kyc-success";
import Loader from "components/common/Loader";

import { useSubmittedOnfidoVerification } from "./service";

const OnfidoKycCallback = () => {
  const { mutate: mutateSubmittedOnfidoVerificationData, isLoading: isLoadingSubmit } =
    useSubmittedOnfidoVerification();

  useEffect(() => {
    mutateSubmittedOnfidoVerificationData();
  }, []);

  return isLoadingSubmit ? <Loader /> : <KycSubmitted />;
};

export default OnfidoKycCallback;
